import React, { useEffect } from 'react';
import './CSS/projects.css';
import Footer from '../Footer/Footer';

function Home() {
  useEffect(() => {
    var TxtType = function(el, toRotate, period) {
      this.toRotate = toRotate;
      this.el = el;
      this.loopNum = 0;
      this.period = parseInt(period, 10) || 2000;
      this.txt = '';
      this.tick();
      this.isDeleting = false;
      this.finished = false;
    };

    TxtType.prototype.tick = function() {
      var i = this.loopNum % this.toRotate.length;
      var fullTxt = this.toRotate[i];

      if (this.isDeleting) {
        this.txt = fullTxt.substring(0, this.txt.length - 1);
      } else {
        this.txt = fullTxt.substring(0, this.txt.length + 1);
      }

      this.el.innerHTML = '<span class="wrap">' + this.txt + '</span>';

      var that = this;
      var delta = 100 - Math.random() * 50;

      if (this.isDeleting) {
        delta /= 2;
      }

      if (!this.isDeleting && this.txt === fullTxt) {
        if (!this.finished) {
          delta = this.period;
          this.isDeleting = true;
        } else {
          delta = 1000;
        }
      } else if (this.isDeleting && this.txt === '') {
        if (!this.finished) {
          this.isDeleting = false;
          this.loopNum++;
          if (this.loopNum === this.toRotate.length) {
            this.finished = true;
            this.toRotate = ["Software Developer"];
          }
          delta = 500;
        } else {
          delta = 1000;
        }
      }

      setTimeout(function() {
        that.tick();
      }, delta);
    };

    window.onload = function() {
      var elements = document.getElementsByClassName('typewrite');
      for (var i = 0; i < elements.length; i++) {
        var toRotate = elements[i].getAttribute('data-type');
        var period = elements[i].getAttribute('data-period');
        if (toRotate) {
          new TxtType(elements[i], JSON.parse(toRotate), period);
        }
      }
      // INJECT CSS
      var css = document.createElement("style");
      css.type = "text/css";
      css.innerHTML = ".typewrite > .wrap { border-right: 0.08em solid #fff }";
      document.body.appendChild(css);
    };
  }, []);


  return (
    <div>
      <div className='text-image-container'>
        <div className='text1'>
          <div className='text-container'>
            <h1 className="welcome-text">
             <strong className='MyFuture'>Coming Soon!</strong>
            </h1>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Home;
