import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Navbar from './components/Navbar/Navbar';
import Home from './components/Home/Home';
import Home2 from './components/Home/Home2';
import Projects from './components/Projects/projects';
import AnimatedBackground from './components/AnimatedBackground/AnimatedBackground'; // AnimatedBackground bileşenini içe aktar

function App() {
  return (
    <Router>
      <AnimatedBackground />
      <Navbar />
      <BackgroundColorController />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/home2" element={<Home2 />} />
        <Route path="/projects" element={<Projects />} />
      </Routes>
    </Router>
  );
}

function BackgroundColorController() {
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === '/home2') {
      document.body.style.backgroundColor = 'black';
    } else {
      document.body.style.backgroundColor = ''; // Varsayılan arka plan rengi
    }

    return () => {
      document.body.style.backgroundColor = ''; // Temizlik
    };
  }, [location.pathname]);

  return null;
}

export default App;
