// src/components/Footer.js
import React from 'react';
import './CSS/Footer.css';

function Footer() {
  return (
    <footer>
      <div className="footer-left">
        <p>Created by Kutay Akgumus</p>
      </div>
      <div className="footer-right">
        <a href="https://www.instagram.com/kutayakgumus" target="_blank" rel="noopener noreferrer">d</a>
        <a href="https://www.linkedin.com/in/kutayakgumus/" target="_blank" rel="noopener noreferrer">j</a>
        <a href="https://github.com/KutayAkg" target="_blank" rel="noopener noreferrer">X</a>
      </div>
    </footer>
  );
}

export default Footer;
