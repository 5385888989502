// Home2.js
import React, { useEffect } from 'react';
import './CSS/Home2.css'; // Home2 için CSS dosyası
import homeImage from './Images/AboutMePhoto.png';

function Home2() {
  return (
<div className="home2-background">
  <h2 className='About-Me'>About Me</h2>
  <div className='content-container'>
    <img src={homeImage} alt="Homepage" className="homepage-image" />
    <p className='About-Me-Text'>
    Hi there! I'm Kutay, a software enthusiast and explorer of digital realms. 🌐 My journey in programming started at the age of 13, creating simple websites with HTML. Back then, I was stuck between "colors" and "fonts," but as I delved into CSS and JavaScript, I came closer to uncovering the mysteries of the digital world. 💻

During my university years, my fascination with backend development soared. 🚀 I’ve immersed myself in C, C#, PHP, database management, and Python, diving deep into the world of coding. In short, getting lost in code has become a way of life for me. 📚

From creating simple sites with HTML in the past to working on more complex and impressive projects now, I find great joy in learning and developing every day. Whether it’s writing code or solving problems, the world of software is both a passion and a playground for me. 🌟

Let’s embark on a digital adventure together and continue our journey in the fascinating world of programming! 🌟
<br />
      <b>Stay tech-savvy! 👨‍💻 </b>
    </p>
  </div>
</div>

  );
}

export default Home2;
