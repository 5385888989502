import React, { useEffect, useRef } from 'react';
import './AnimatedBackground.css';

const AnimatedBackground = () => {
    const backgroundRef = useRef(null);
    const symbols = [
        'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z',
        'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z',
        '1', '2', '3', '4', '5', '6', '7', '8', '9'
    ];

    useEffect(() => {
        const background = backgroundRef.current;
        let dots = [];

        const createDots = () => {
            background.innerHTML = ''; // Var olan tüm noktaları temizle
            dots = [];
            const numDots = 100; // Nokta sayısı

            for (let i = 0; i < numDots; i++) {
                const dot = document.createElement('div');
                dot.className = 'dot';
                dot.style.left = Math.random() * window.innerWidth + 'px';
                dot.style.top = Math.random() * window.innerHeight + 'px';

                // Random bir simge seç ve dot içeriğine ata
                dot.innerText = symbols[Math.floor(Math.random() * symbols.length)];
                background.appendChild(dot);
                dots.push(dot);

                animateDot(dot);
            }
        };

        const animateDot = (dot) => {
            const duration = Math.random() * 5000 + 5000; // 5 ile 10 saniye arasında
            const distance = Math.random() * 100 + 50; // 50 ile 150 px arasında
            const keyframes = [
                { transform: `translate(${Math.random() * distance}px, ${Math.random() * distance}px)` },
                { transform: `translate(${Math.random() * distance}px, ${Math.random() * distance}px)` },
            ];

            dot.animate(keyframes, {
                duration: duration,
                iterations: Infinity,
                easing: 'linear'
            });
        };

        createDots(); // İlk başta noktaları oluştur

        const handleResize = () => {
            createDots(); // Pencere boyutlandırıldığında noktaları yeniden oluştur
        };

        window.addEventListener('resize', handleResize); // Pencere boyutlandırma olayını dinle

        return () => {
            window.removeEventListener('resize', handleResize); // Component unmount olduğunda dinleyiciyi kaldır
        };
    }, []);

    return <div className="background" ref={backgroundRef}></div>;
};

export default AnimatedBackground;
